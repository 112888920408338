export const useAsyncState = () => {
  const isLoading = ref(false)

  function startLoading() {
    isLoading.value = true
  }

  function stopLoading() {
    isLoading.value = false
  }

  const _error = ref('')
  const hasError = computed(() => !!_error.value)
  const error = computed(() => (_error.value ? `Error: ${_error.value}` : ''))

  function setError(str = '') {
    _error.value = str
  }

  return {
    error,
    hasError,
    isLoading,
    setError,
    startLoading,
    stopLoading,
  }
}
